import type { API } from "@/hl-common/types/api/API";
import { Routes } from "@/hl-common/types/api/routes";
import { _request } from "./fetch";

export const me: API.me.func = async () => _request(Routes.me);

export const createAuthChallenge: API.createAuthChallenge.func = async (args) =>
  _request(Routes.createAuthChallenge, args);

export const verifyOtp: API.verifyOtp.func = async (args) =>
  _request(Routes.verifyOtp, args);

export const verifyPassword: API.verifyPassword.func = async (args) =>
  _request(Routes.verifyPassword, args);

export const logout: API.logout.func = async () => _request(Routes.logout);

export const registerUser: API.registerUser.func = async (args) =>
  _request(Routes.registerUser, args);

export const updateUserEmail: API.updateUserEmail.func = async (args) =>
  _request(Routes.updateUserEmail, args);

export const updateUserName: API.updateUserName.func = async (args) =>
  _request(Routes.updateUserName, args);

export const getUserNotificationSettings: API.getUserNotificationSettings.func =
  async (args) => _request(Routes.getUserNotificationSettings, args);

export const getUserNameChangeRequests: API.getUserNameChangeRequests.func =
  async (args) => _request(Routes.getUserNameChangeRequests, args);

export const updateUserNotificationSetting: API.updateUserNotificationSetting.func =
  async (args) => _request(Routes.updateUserNotificationSetting, args);

export const getCoursesWithStatus: API.getCoursesWithStatus.func = async () =>
  _request(Routes.getCoursesWithStatus);

export const getCourseWithStatus: API.getCourseWithStatus.func = async (args) =>
  _request(Routes.getCourseWithStatus, args);

export const getCourse: API.getCourse.func = async (args) =>
  _request(Routes.getCourse, args);

export const getLandingPage: API.getLandingPage.func = async (args) =>
  _request(Routes.getLandingPage, args);

export const getModule: API.getModule.func = async (args) =>
  _request(Routes.getModule, args);

export const getModuleFull: API.getModuleFull.func = async (args) =>
  _request(Routes.getModuleFull, args);

export const getUserEventsByCourse: API.getUserEventsByCourse.func = async (
  args,
) => _request(Routes.getUserEventsByCourse, args);

export const getCompletedCardsByModule: API.getCompletedCardsByModule.func =
  async (args) => _request(Routes.getCompletedCardsByModule, args);

export const ingestEvent: API.ingestEvent.func = async (args) =>
  _request(Routes.ingestEvent, args);

export const verifyCourseComplete: API.verifyCourseComplete.func = async (
  args,
) => _request(Routes.verifyCourseComplete, args);

export const getUserEventsCourseComplete: API.getUserEventsCourseComplete.func =
  async () => _request(Routes.getUserEventsCourseComplete);

export const syncTags: API.syncTags.func = async (args) =>
  _request(Routes.syncTags, args);
